.date-form-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-value {
  margin: 0 20px;  
}

.arrow-button {  
  margin: 5px;
  font-weight: bolder;
}
